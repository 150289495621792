// src/data/apps.js
import expenseTracker from "../images/pr_source.png";
import splitEqual from "../images/splitequal.png";
import todoList from "../images/todolist.jpg";
import qrGenerator from "../images/qr.jpg";
import bmiTracker from "../images/bmi.jpg";
import money from "../images/money.jpg";
const apps = [
  {
    name: "Expense Tracker",
    shortname: "Expense",
    href: "/app1/home",
    image: expenseTracker,
    favotire: false,
    display: true,
  },
  {
    name: "Split Equal",
    shortname: "Split",
    href: "/app2/home",
    image: splitEqual,
    favotire: false,
    display: true,
  },
  {
    name: "To Do List",
    shortname: "ToDo",
    href: "/app4/home",
    image: todoList,
    favotire: false,
    display: true,
  },
  {
    name: "QR Generator",
    shortname: "QR",
    href: "/app3/createqr",
    image: qrGenerator,
    favotire: false,
    display: false,
  },
  {
    name: "Weight/BMI Tracker",
    shortname: "BMI",
    href: "/app5/home",
    image: bmiTracker,
    favotire: false,
    display: true,
  },
  {
    name: "Financial Calculator",
    shortname: "Finance",
    href: "/app6/sip",
    image: money,
    favotire: false,
    display: true,
  },
];
export default apps;
