// Admin/ManageApps.js
import React from "react";
import config from "../config.json";

const PrivacyPolicy = () => (
  <div className="container fs-6">
    <h1>Privacy Policy</h1>
    <p>Last updated: November 07, 2024</p>
    <section>
      <p>This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
      <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.</p>

      <h2>Interpretation and Definitions</h2>
      <h3>Interpretation</h3>
      <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
        </li>
        <li>
          <strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.
        </li>
        <li>
          <strong>Application</strong> refers to {config.apptitle}, the software program provided by the Company.
        </li>
        <li>
          <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to {config.apptitle}.
        </li>
        <li>
          <strong>Country</strong> refers to: Tennessee, United States
        </li>
        <li>
          <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.
        </li>
        <li>
          <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
        </li>
        <li>
          <strong>Service</strong> refers to the Application.
        </li>
        <li>
          <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service, or to assist the Company in analyzing how the Service is used.
        </li>
        <li>
          <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
        </li>
        <li>
          <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
        </li>
      </ul>

      <h2>Collecting and Using Your Personal Data</h2>
      <h3>Types of Data Collected</h3>
      <h4>Personal Data</h4>
      <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Usage Data</li>
      </ul>

      <h4>Usage Data</h4>
      <p>Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>

      <h3>Password Security</h3>
      <p>We take the security of your information seriously. To ensure the safety of your account, any passwords you set for this Service will be encrypted before storage. This means that your password is transformed into a secure format that cannot be easily read or accessed. This encryption helps prevent unauthorized access to your account, and we recommend that you choose a strong, unique password to further protect your information.</p>

      <h3>Use of Your Personal Data</h3>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul>
        <li>To provide and maintain our Service, including monitoring usage.</li>
        <li>To manage Your Account and provide access as a registered user.</li>
        <li>For contract performance, such as the purchase contract for products, items, or services.</li>
        <li>To contact You regarding updates or other information about functionalities, products, services, and more.</li>
        <li>To provide You with news, special offers, and general information.</li>
        <li>To manage Your requests to Us.</li>
        <li>For business transfers, mergers, sales, or asset transfers.</li>
        <li>For other purposes, such as data analysis, usage trend analysis, and service improvement.</li>
      </ul>

      <h3>Retention of Your Personal Data</h3>
      <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

      <h3>Security of Your Personal Data</h3>
      <p>The security of Your Personal Data is important to Us, but no method of transmission or storage is 100% secure. While we strive to protect Your data, absolute security cannot be guaranteed.</p>

      <h3>Children's Privacy</h3>
      <p>Our Service does not address anyone under the age of 13. We do not knowingly collect information from anyone under the age of 13. If you are aware that a child has provided us with Personal Data, please contact Us.</p>

      <h3>Changes to this Privacy Policy</h3>
      <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, You can contact us:</p>
      <ul>
        <li>
          By email: <a href="mailto:Contactssnapps@gmail.com">contactssnapps@gmail.com</a>
        </li>
        <li>
          By visiting this page on our website:{" "}
          <a href="https://apps.eshope.com/public/privacypolicy" target="_blank">
            here
          </a>
        </li>
      </ul>
    </section>

    <div>
      <small>&copy; 2024 {config.apptitle}</small>
    </div>
    <div className="container mb-4"></div>
  </div>
);

export default PrivacyPolicy;
