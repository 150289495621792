import React, { useState } from "react";

const Shared = () => {
  return (
    <div className="container">
      <h2>Shared List</h2>
      <p>Log in to access this feature.</p>
    </div>
  );
};

export default Shared;
