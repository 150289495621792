import React, { useState } from "react";

const Shared = () => {
  return (
    <div className="container">
      <h2>Approvals</h2>
    </div>
  );
};

export default Shared;
