// Admin/ManageApps.js
import React from "react";

const ManageApps = () => (
  <div className="admin-page">
    <h2>Manage Apps</h2>
    <p>This is the Manage Apps page.</p>
  </div>
);

export default ManageApps;
